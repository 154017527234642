.btn_unblock {
  border: 1px solid #FC5143;
  border-radius: 1.2rem;
  color: #FC5143;
  box-shadow: 0px 10px 20px #425FA00D;
  padding: 0.6rem 1.6rem;
  font-size: 1.4rem;
  font-family: "Poppins medium";

  &:hover {
    border: 1px solid #32CD32;
    color: #32CD32;
  }
}

.custBtn {
  background-color: #4395FF;
  border-radius: 1.2rem;
  color: #ffffff;
  padding: 1.2rem;
  width: 100%;
  font-size: 1.8rem;
  font-family: "Poppins medium";
}

// tabs
.nav-tabs {
  border-bottom: none;
}

.pointerNone {
  pointer-events: none;
}

.urlSpan {
  text-decoration: underline;
  text-decoration-color: rosybrown;
  cursor: pointer;
  color: rgb(44, 44, 124);
}

.delCls {
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-left: 0.5rem;
}

.modContent {
  padding: 20px;

  .crossBtn {
    display: flex;
    justify-content: end;

    img {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  .modText {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

  }

  .modBtn {
    display: flex;
    justify-content: center;
    padding: 25px 0px;

    .btnDel {
      width: 100px;
      padding: 10px;
      cursor: pointer;
      color: #ffffff;
      border-radius: 50px;
      background-color: #4395FF;
      font-size: 16px;
      margin-right: 4rem;


    }

    .btnNo {
      width: 100px;
      padding: 10px;
      cursor: pointer;
      color: black;
      border-radius: 50px;
      background-color: lightgrey;
      font-size: 16px;


    }
  }
}

.ovHidUnset {
  overflow: unset !important;
}