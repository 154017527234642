@font-face {
    font-family: "Poppins regular";
    src: url("../fonts/Poppins-Regular.woff");
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: "Poppins medium";
    src: url("../fonts/Poppins-Medium.woff");
    font-weight: 500;
    font-style: normal;
  }