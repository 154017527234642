.chat-onboarding {
  background-image: linear-gradient(0deg,
      rgba(255, 255, 255, 0.829),
      hsla(213, 97%, 63%, 0.747)),
    url(../images/onboarding-bg.jpeg);
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;
  padding: 5% 0;
  background-size: cover;
  display: flex;
  align-items: center;

  .required {
    padding: 0px 4px;
    color: red;
  }

  select,
  input[type="text"],
  input[type="password"] {
    width: 100%;
    border-radius: 10px;
    background-color: #f8faff;
    border: 1px solid #e5ebf5;
    height: 50px;
    font-size: 16px;
    color: #252f40;
    padding: 1rem 2rem;

    &:focus,
    &:focus-visible {
      box-shadow: none;

      outline: none;
    }

    option {
      font-size: 1.6rem;
      color: #252f40;
      font-weight: normal;
    }
  }

  .middle {
    margin: auto;

    .logo-area {
      margin-bottom: 4rem;

      img {
        width: 45%;
      }
    }

    .password_form {
      position: relative;

      .password_icon {
        position: absolute;
        top: 5rem;
        right: 1rem;
        cursor: pointer;

        @media (max-width:480px) {
          top: 4.3rem;
        }
      }
    }

    .onboarding-heading {
      text-align: center;
      margin-bottom: 3rem;
      color: #252f40;

      h3 {
        font-size: 3rem;
        letter-spacing: 1px;
      }
    }

    .onboarding-tagline {
      text-align: center;
      width: 100%;
      font-size: 1.4rem;
      color: #252f40;
      font-family: "Poppins-Regular";
      margin-bottom: 3rem;
    }

    .onboarding-box {
      margin: auto;
      background-color: #fff;
      border-radius: 2rem;
      box-shadow: 5px 5px 30px #425fa01a;
      box-sizing: border-box;
      padding: 4rem;

      @media (max-width:991px) {
        padding: 3rem 2rem;
      }

      .form-group {
        margin-bottom: 3rem;

        @media (max-width:480px) {
          margin-bottom: 2rem;
        }
      }
    }

    .submit_button {
      .send_content {
        background: #0368a7;
        padding: 13px;
        color: #fff;
        font-size: 17px;
        border-radius: 10px;
        margin-top: 4rem;
      }
    }
  }

  label {
    color: #252f40;
    font-size: 16px;
    width: 100%;
    margin-bottom: 0.5rem;

    .label-filed {
      margin-bottom: 11px;
      display: inline-table;

      @media (max-width:480px) {
        margin-bottom: 5px;
      }
    }
  }

  .form-label {
    margin-bottom: 11px;
  }

  .role-selector {
    // justify-content: center;
    margin-bottom: 11px;

    .inputField {
      margin: 0px 13px 0 0;

      &:first-child {
        input {
          margin-left: 0;
        }
      }

      label {
        margin-bottom: 0;
        // padding: 0 10px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        position: relative;

        input {
          margin: 0 10px 0px 50px;
        }

        .label-filed {
          position: absolute;
          bottom: 9px;
          width: 100%;
          margin: 0;
          text-align: center;
        }
      }
    }

    .radio-img {
      width: 70px;

      @media (max-width:991px) {
        width: 50px;
      }
    }

    .radio-imgs {
      width: 135px;
      height: 121px;
      text-align: center;
      padding: 12px;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid #0368a7;
      background-color: #0368a71c;

      @media (max-width:991px) {
        width: 110px;
        height: 100px;
        padding: 5px;
      }
    }

    .roleSelector {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;

      +img {
        cursor: pointer;
      }

      &:checked+.radio-imgs {
        border: 2px solid #0368a7;

        &:after {
          content: "";
          position: absolute;
          top: -8px;
          width: 25px;
          height: 25px;
          left: -6px;
          background-image: url("../images/check2.png");
          border-radius: 50%;
          background-size: cover;
        }
      }
    }
  }
}