.input-group {
  margin-bottom: 1.5rem;
}

label {
  font-size: 1.6rem;
  color: #252f40;
  font-family: "Poppins medium";
}

.cust_formSelect {
  margin-top: 0.7rem;
  font-family: "Poppins regular";
  color: #999999;
  border: 1px solid #e5ebf5;
  border-radius: 1.2rem !important;
  background-color: #ffffff !important;
  padding: 1.3rem 2rem;
  background: url("../images/icons/arrow-down\ _gray.svg") no-repeat;
  background-position: top 16px right 1.5rem;
  appearance: none;

  &:active {
    background: url("../images/icons/arrow_up_gray.svg") no-repeat;
    background-position: top 16px right 1.5rem;
  }
}

.input_send {
  display: flex;
  align-items: center;

  .chat_input {
    flex: 1;
    margin-right: 1rem;
  }

  .send_content {
    background-color: #55a55e;
    border-radius: 50%;
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -3px;

    @media (max-width: 480px) {
      width: 4rem;
      height: 4rem;
    }
  }
}

.input_prof_icon {
  position: absolute;
  transform: translateY(11px);
  left: 1rem;
 @media (max-width: 480px) {
  transform: translateY(6px);
 }
}

.input_prof_icon {
  img {
    height: 3rem;
    width: 3rem;
  }
}

.input_file_icon {
  position: absolute;
  transform: translateY(11px);
  right: 1rem;
  cursor: pointer;

  img {
    cursor: pointer;
  }
}

textarea {
  border: none;
  font-size: 14px;
  color: #999999;
  outline: none;
  overflow: auto;
  word-wrap: break-word;
  word-break: break-all;
  resize: none;
  border-radius: 1.2rem !important;
}

.textarea_send {
  padding: 1.5rem 5rem;
  background: #99c0ff36;
  color: #000;

  @media (max-width:480px) {
    padding: 1rem 3rem 1rem 4.5rem;
  }
}

.textarea_input {
  margin-top: 0.7rem;
  min-height: 18.8rem;
  border: 1px solid #e5ebf5;
  padding: 1.3rem 2rem;
  margin-bottom: 7rem;
}

input {
  font-family: "Poppins regular";
  background-color: #ffffff !important;
  font-size: 1.4rem;
}

.search_icon {
  background: url("../images/icons/search_icon.svg") no-repeat;
  background-position: center left 1rem;
  background-size: 18px 18px;
  border: 1px solid #dedede;
  width: 100%;
  border-radius: 8px;
  padding: 1rem 3rem;
  padding-left: 3.7rem;
}

// chat box with media box 

.chat_box_body {
  .input_send {
    position: absolute;
    bottom: 0px;
    width: 100%;
    max-width: 95%;
    z-index: 99;
    left: 0;
    right: 0;
    margin: auto;
    align-items: flex-end;
    padding: 19px 16px 10px 14px;
    background-color: #ffffff;
  }

  .textarea_send {
    // background: transparent;
    background:
      #e8f2ff;
  }

  .chat_input {
    background: #99c0ff36;
    border-radius: 1.2rem;
    position: relative;

    .media-box {
      .media-content {
        width: 100%;
        min-height: 140px;
        object-fit: contain;
        max-height: 150px;
      }
    }

    .crossimg {
      right: -5px;
      position: absolute;
      top: -4px;
      cursor: pointer;
    }
  }
}