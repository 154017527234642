body {
    margin: 0;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

ul,
ol {
    list-style: none;
}

button {
    border: none;
}

button:focus,
input:focus {
    outline: none;
}

.chat-img {
    object-fit: cover;
    cursor: pointer;
    // width: 100%;
    // height: 100%;
    width: 100%;
    z-index: 9;
    object-fit: cover;
    max-width: 250px;
}

img.pdf-icon {
    width: 40px;
    height: 40px;
    filter: invert(1);
}

span.attachment-title {
    margin-left: 11px;
}