.alert {
  display: none;
  transform: translate(400px, 0px);
  cursor: pointer;
  &.toast-show {
    position: fixed !important;
    z-index: 2000 !important;
    top: 80px;
    right: 15px;
    font-size: 16px;
    opacity: 5;
    display: block;
    max-width: 310px;
    width: 100%;
    border: 1px solid #dedada;
    border-radius: 5px;
    box-shadow: 0 0 6px;
    animation-name: showTost;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    .alert-heading {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }
}

@keyframes showTost {
  to {
    transform: translate(0px, 0px);
  }
}
