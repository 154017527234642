// boostrap
@import "../node_modules/bootstrap/scss/bootstrap";

@import "./assets/scss/fonts";

@import "./assets/scss/variables";

//base
@import "../src/assets/scss/base";

// onboarding
@import "../src/assets/scss/onboarding";

//button
@import "../src/assets/scss/btn";

//check box and toggle button
@import "../src/assets/scss/checkbox";

// form
@import "../src/assets/scss/form";

// modal
// @import "../src/assets/scss/modal";

//scroll
@import "../src/assets/scss/scroll";

// typography
@import "../src/assets/scss/typography";

// utilites
@import "../src/assets/scss/utilites";

//  ========================================= common =====================================

.hand {
    cursor: pointer;
}