.row {
  margin: 0;
}

.cursor_pointer {
  cursor: pointer;
}

.mt-2t {
  margin-top: 2rem;
}

.mr-1r {
  margin-right: 1rem;
}

.bg_garkGray {
  background-color: #0419441a;
}

.bg_deepSkyBlue {
  background-color: #4395fc;
}

col {
  padding: 0;
}

.boder-rad {
  border-radius: 50%;
}

.brd_gray {
  border: 2px solid #999999;
}

.brd_unread {
  border: 2px solid #37c3a8 !important;
}

.brd_white {
  border: 2px solid #ffffff;
}

.brd_blue {
  border: 1px solid #4394fc;
}

div.active_blue_txt {
  color: #4395fc;
}

.active_blue_txt {
  color: #4395fc !important;
}

//   ====================================== custom
//  {/* tabs html */}
.cust_dropdwonStyle {
  .dropdown-menu {
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    padding: 1.2rem 1rem;
    border-radius: 5px;
    // transform: translate(-16px, 32px) !important;
    transform: translate(15px, 19px) !important;
    width: 230px;

  }

  .btn-primary {
    border-color: transparent !important;
  }

  .dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .drop-text {
    margin-left: 4rem;
    display: block;
    margin-top: -4px;
    cursor: pointer;
  }

  .dropdown-item {
    padding: 8px 0;
    border-bottom: 2px solid #00000029;
    color: #252f40;
    font-size: 14px;

    &:hover {
      background-color: transparent;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .dropdown_itemIcon {
      margin-right: 1.7rem;
    }
  }
}

// .toggle-group {
//   .switch {
//     left: -1rem;
//   }
// }

.chat_tab {
  .nav-tabs {
    margin: auto 2.5rem;

    button {
      width: 100%;
      color: #252f40;
      font-size: 1.6rem;
      font-family: "Poppins medium";
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;
      background-color: #ffffff;
      box-shadow: 0px 6px 20px #d6e3e98a;

      @media (max-width:480px) {
        font-size: 14px;
        padding: 8px;
      }
    }

    .nav-item {
      &:first-child {
        button {
          border-radius: 12px 0px 0px 12px;
        }
      }

      &:last-child {
        button {
          border-radius: 0px 12px 12px 0px;
        }
      }

      border-right: 2px solid #eaeaea;

      &:last-child {
        border-right: none;
      }
    }

    .nav-link.active {
      background-color: #4395fc;
      color: #ffffff;
      border-color: #4395fc;
    }
  }
}

.center_tab {
  border-left: 2px solid #dedede;
  border-right: 2px solid #dedede;
}

.active_tab {
  color: #ffffff;
  background-color: #4395fc;

  &:hover {
    color: #ffffff;
  }
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

//  {/* tabs html */}
// screen 1
.live_text {
  margin-left: 1.5rem;
  font-family: "Poppins medium";
  color: #000;
}

.umgs {
  color: #999999;
  font-size: 12px;
  font-family: "Poppins regular";

  span {
    color: #4395fc;
    margin-right: 0.3rem;
  }
}

h2.ht {
  font-size: 2.2rem;
  color: #252f40;
  font-family: "Poppins medium";

  @media (max-width: 575px) {
    font-size: 2rem;
  }

}

.chat_box_ui {
  .chat_box_uiImg {
    min-height: 100vh;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.chat_box {
  padding: 2.8rem 0 0;
  overflow: auto;
  position: relative;
  // min-height: 100vh;
  height: 100%;
  background: #fff;
  box-shadow: 9px 19px 10px 9px #bdbedd80;

  @media (max-width: 767px) {
    // height: auto;
    // min-height: 89vh;
    padding-top: 20px;
  }

  // .tab-content {
  //   height: 83vh;
  //   overflow: auto;
  //   // .input-box {
  //   //   padding: 0 13px;
  //   // }
  // }
}

.right_cont {
  a {
    margin-right: 1.5rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
}

// card screen 1
// screen 12
.cus_btn_cont {
  margin: 0 21px;
}

.card_head_tp {
  color: #252f40;
  font-size: 1.4rem;
  font-family: "Poppins regular";
  margin-bottom: 1rem;
  padding: 0 26px;
}

// end screen 12
.chat_card_container {
  overflow-y: auto;
  padding-right: 3px;

  // margin: 0 -15px;
  // &.liveClass_viewH {
  //   height: 65vh;
  // }
  // &:last-child {
  //   .chat_boxS {
  //     margin-bottom: 0;
  //   }
  // }
  .chat_boxS:first-child {
    margin-top: 5px;
  }

  &:first-child {
    .chat_boxS {
      margin-bottom: 15px;
    }
  }
}

.chat_card_container.bottombtn {
  // height: 72vh;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.chat_card {
  margin-bottom: 1.5rem;
  border-radius: 1.2rem;
  background-color: #ffffff;

  .card_img {
    margin-right: 1rem;
  }
}

h4.ulive {
  font-size: 1.6rem;
  color: #252f40;
  font-family: "Poppins medium";
  margin-bottom: 0;

  @media (max-width: 575px) {
    font-size: 14px;
  }
}

h5.uname {
  font-size: 1.2rem;
  color: #252f40;
  font-family: "Poppins regular";
  word-break: break-all;
}

.time_con {
  font-size: 1.2rem;
  color: #999999;
  font-family: "Poppins regular";
}

.small_user-icon {
  width: 3.6rem;
  height: 3.6rem;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}

.card_img {
  position: relative;
}

.active-dot-icon {
  position: absolute;
  width: 10px;
  height: 10px;
  right: -2px;
  border-radius: 50%;
  bottom: 5px;
  background-color: #198754;
}

.active-dot-icon-img {
  border: 1.5px solid #198754;
}

.small_iconSett {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: cover;
  border-radius: 50%;
}

// profile container
.auth_cont {
  font-size: 1.4rem;
  font-family: "Poppins regular";
}

.profileIcon_big_conatiner {
  .profile_img {
    width: 14rem;
    height: 14rem;
  }
}

// form section

.chat_Pbt {
  padding: 1.7rem 1.2rem;

  @media (max-width:480px) {
    padding: 1rem 0.5rem;
  }
}

.chat_boxS {
  box-shadow: 0px 6px 20px #d6e3e98a;
  margin: 15px 23px 15px;
}

.chat_search {
  margin: 2rem 2.5rem;
}

.add_camera_search {
  gap: 1rem;

  .search_icon {
    background: transparent;
    padding: 1rem 1.5rem;
  }
}

.ht_data {
  color: #4394fc;
}

.chat_head_gap {
  gap: 1rem;
}

.live_txt-con {
  top: 7rem;
  left: 7rem;
  color: #ffffff;
  font-size: 2.2rem;
}

.group_uname {
  // position: absolute;
  position: relative;
  padding: 5px 17px;
  border-radius: 12px 12px 0px 0px;
  top: 0;
  color: #4395fc;
  left: 0;
  background-color: #0419441a;
  width: 100%;
  font-size: 12px;
  min-height: 2.9rem;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;

  div {
    min-width: fit-content !important;
  }

  .dropstart {
    position: absolute;
    right: 12px;
    top: 4px;

    button {
      padding: 0;
      text-align: center;

      img {
        width: 14px;
      }
    }

  }
}

.chat-header-content {
  padding-right: 20px;
}

.cm-msg-text {
  font-family: "Poppins regular";
  text-align: left;
  // padding: 5px 17px;
  padding: 18px 13px;
  font-size: 1.4rem;
}

.chat-logs {
  // max-height: 80vh;
  // max-height: 82vh;
  // overflow-y: auto;
  margin-top: 10px;

  @media (max-width: 767px) {
    padding-bottom: 0;
    max-height: none;
    max-height: initial;
    overflow-y: inherit;
  }

  // @media (max-width: 575px) {
  //   height: 71vh;
  // }

  .time_con {
    margin-bottom: 0.7rem;

    @media (max-width: 1199px) {
      margin-bottom: 15px;
    }
  }
}

.addMember {
  position: fixed;
  right: 5rem;
  bottom: 2.5rem;
  cursor: pointer;

  &:active {
    transform: scale(0.97);
  }
}

.chat-msg {
  padding: 1.3rem 1.5rem;
  display: inline-block;
}

.user_people {
  .chat-msg {
    background-color: #0419441a;
    border-radius: 12px 12px 12px 0px;
  }

  .cm-msg-text {
    color: #252f40;
  }

  .highlightDiv {
    // #2366A6 #B0A9B2 
    border: 1px solid #2366A6;
  }
}

.self_people {
  text-align: right;

  .chat-msg {
    background-color: #4395fc;
    border-radius: 12px 12px 0px 12px;

    .group_uname {
      img {
        filter: invert(248%);
      }
    }
  }

  .chat-msg_teacher {
    // background-color: #4395fc;
    background-color: #43fc94;
    border-radius: 12px 12px 0px 12px;

    .group_uname {
      img {
        filter: invert(248%);
      }
    }
  }

  .time_con {
    text-align: right;
  }

  .cm-msg-text {
    color: #ffffff;
  }

  .dropdown-menu {
    transform: translate(-3px, 61%) !important;
    padding: 7px 11px !important;
    width: fit-content !important;
  }
}

.group_uname_box {
  min-height: 71px;
  overflow: hidden;
  position: relative;
  // padding-top: 4.2rem;
  // width: 100%;
  padding: 0;
  width: auto;

}

// 7-july
.left-section {
  // background-image: linear-gradient(45deg,
  //     rgba(255, 255, 255, 0.877),
  //     transparent),
  //   url(../images/chat-bg.png);
  background-image: url(../images/chat-bg.png);
  background-size: cover;
  // background-attachment: fixed;
  background-position: center;
  height: 100%;

  @media (max-width:767px) {
    background-image: none;
  }

  .logo-filed {
    height: 100vh;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    // width: 163vh;
    margin: auto;

    @media (max-width:767px) {
      height: auto;
      width: 100%;
      padding: 15px 0;
      display: block;
    }

    img {
      @media (min-width: 768px) {
        display: none;
      }

      position: relative;
      width: 284px;

      @media (max-width:767px) {
        max-width: 160px;
      }

      @media (max-width: 575px) {
        max-width: 135px;
      }
    }
  }
}

.right-sec {
  .chat_header {
    // padding: 0 0 7px;
    margin: auto 15px;
  }

  .chat_box_body {
    // padding: 19px 24px 0;
    display: flex;
    // height: 100%;
    // position: relative;
    flex-direction: column;
    height: calc(100vh - 150px);
    // height: calc(100vh - 75px);
    padding: 19px 0 0 24px;
    margin-top: 10px;
    background-image: linear-gradient(45deg,
        rgba(255, 255, 255, 0.973),
        rgba(255, 255, 255, 0.747)),
      url(../images/chat-icon-bg.png);
    background-position: center;
    overflow: auto;
    // max-height: 91vh;
    // padding-bottom: 84px;

    @media (max-width: 767px) {
      padding: 19px 15px 0;
      margin-top: 0;
      max-height: 83.6vh;
      overflow: auto;
      padding-bottom: 84px;
    }

    @media (max-width:480px) {
      padding-bottom: 74px;
    }

    // @media (max-width: 575px) {
    //   padding: 19px 10px 0;
    // }
  }
}

// 11 july
.cursor {
  cursor: pointer;
}

// 14 july
.chat-tabs {
  padding-bottom: 0;
}

.live-class {
  .chat_card {
    border: 2px solid #999999;
    cursor: pointer;
  }
}

// 15 july
.tab-listing {
  // min-height: 74.3vh;
  // max-height: 74.3vh;
  // height: 100vh;
  overflow: auto;

  @media (max-width: 767px) {
    min-height: auto;
    max-height: initial;
    height: auto;
  }
}

.participant-listing {
  max-height: 70vh;
  height: 100%;
  overflow: auto;
}

.contact-listing {
  max-height: 81vh;
}

li.nav-item {
  width: 100%;
}

li.nav-item button {
  border-radius: 0 !important;
}

@media (max-width:770px) {

  .offset-6,
  .offset-5,
  .offset-4,
  .offset-3 {
    margin-left: 0%;
  }

  // .offset-5 {
  //   margin-left: 0%;
  // }

  // .offset-4 {
  //   margin-left: 0%;
  // }

  // .offset-3 {
  //   margin-left: 0%;
  // }
}

.videoThumb {
  position: relative;
  overflow: hidden;

  // max-width: 120px;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000070;
    z-index: 1;
    top: 0;
    left: 0;
  }
}

.playIc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 35px;
  width: 35px;
  cursor: pointer;
  z-index: 9;
}

.dropstart button::before {
  content: none !important;
}

.video-modal {
  .modal-body {
    div {
      width: 100% !important;
      height: 350px !important;
    }
  }
}

.overFtxt {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .input_send {
//   position: fixed;
//   width: 32%;
//   bottom: 20px;
//   z-index: 9999;

//   @media(max-width:1400px) {
//     width: 40%;
//   }

//   @media(max-width:1200px) {
//     width: 48%;
//   }
// }

.media-box {
  background: #e8f2ff;
  margin: 0 !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: -3px -4px 7px #63646630;
  text-align: center;
}

.chat-history {
  box-shadow: 0 8px 10px rgba(4, 25, 68, 0.05);
  padding: 18px;
  border-radius: 0px 0px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 20px;
    width: 500px;
    cursor: pointer;
  }

  h2 {
    text-align: center;
    margin: 8px 0 5px;
    color: #252F40;
    font-size: 22px;
    font-weight: 600;
    flex: 2;
  }
}

.date-history {
  margin: 20px 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .hyp-dash {
    font-size: 20px;
    padding: 0 19px;
  }

  label {
    img {
      filter: brightness(0.5);
    }
  }

  .from {

    input {
      margin-right: 3rem;
    }
  }

  .to {

    input {
      margin-left: 1rem;
    }
  }

  input {
    border: 0;
    font-size: 0;
    filter: brightness(0.5);
    background-size: 21px;
    background-color: transparent !important;
    padding: 15px 20px;
    background-repeat: no-repeat;
    width: 27px;
    height: 27px;
    background-position: 100%;
    margin: 0 5px 0 0;

  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;

  }

  label {
    padding: 0 20px;
  }

  .d-flex {
    color: #252F40;
    font-size: 16px;
    padding: 0px;
    align-items: flex-end;

    img {
      cursor: pointer;

      &:last-child {
        transform: rotate(180deg);
      }
    }
  }
}

.chat-section {
  height: 100vh;

  .chat_box {
    background: #F8FAFF;
  }
}

.chat-header-btn {
  margin-left: 3rem;

  .chat-btn {
    padding: 3px 13px;
    color: #fff;
    font-size: 16px;
    background: #42c3a8;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: 0.3s ease-in-out all;

    &:hover {
      background-color: transparent;
      border-color: #42c3a8;
      color: #42c3a8;
    }
  }
}