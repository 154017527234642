// toggle button start
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
    top: 3px;
    margin: 0 1rem;
  }
  .togg-slide {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .togg-slide:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .togg-slide {
    background-color: #2196F3;
  }
  input:checked + .togg-slide:before {
    transform: translateX(12px);
  }
  /* Rounded sliders */
  .togg-slide.togg-round {
    border-radius: 10px;
  }
  
  .togg-slide.togg-round:before {
    border-radius: 50%;
  }
  // toggle button end


// custom checkbox css start
.check-container {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    width: 18px;
    height: 18px;
  }
  .toggle_txt {
    font-size: 1.4rem;
    font-family: "Poppins regular";
  }
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid #e4e4e4;
    border-radius: 50%;
  }
  
  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    border: 2px solid #77b2fd;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid #77b2fd;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  // custom checkbox css end